import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Message.module.scss'

const Message = ({ children }) => {
  return <div className={styles.Message}>{children}</div>
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Message }
