// https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews-guide.md#enable-previews-in-prismic

import React, { useEffect } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic'
import { Message } from '@/components/Message'
import { linkResolver } from '@/prismic/helpers/linkResolver'

const IS_BROWSER = typeof window !== 'undefined'

const PreviewPage = () => {
  // Let's use a static query to retrieve all known paths. We'll use it later
  // to navigate to the unpublishedPreview page if the document is not
  // published.
  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)
  const allPaths = allSitePage.nodes.map((node) => node.path)

  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: () => linkResolver,
  })

  // const { isPreview, previewData, path } =
  //   IS_BROWSER && window.__GATSBY_SOURCE_PRISMIC__
  //     ? usePrismicPreview({
  //         repositoryName: process.env.GATSBY_PRISMIC_REPO,
  //         linkResolver: () => linkResolver,
  //       })
  //     : false

  useEffect(() => {
    // if this is not a preview, skip
    if (!IS_BROWSER || isPreview === false || !previewData) return

    // Save the preview data to somewhere globally accessible
    window.__PRISMIC_PREVIEW_DATA__ = previewData

    // Navigate to the document's page if page exists
    if (allPaths.includes(path)) {
      navigate(path)
    } else {
      navigate('/preview-unpublished')
    }
  }, [isPreview, previewData, path, allPaths])

  return (
    <Message>
      {isPreview ? `Loading preview...` : `Error: Preview data is invalid.`}
    </Message>
  )
}

export default PreviewPage
